import { combineReducers } from "redux";

import actions from "../actionTypes";

import {
  isLoadingStateReducer,
  successStateReducer,
  errorStateReducer,
  resetStateReducer,
  createReducer
} from "./config/higherOrderReducer";

import { defaultState } from "./config/defaultState";

const getCandidateList = createReducer(defaultState, {
  [actions.CANDIDATE_LISTING]: isLoadingStateReducer,
  [actions.CANDIDATE_LISTING_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_LISTING_ERROR]: errorStateReducer,
  [actions.CANDIDATE_LISTING_CLEAR]: resetStateReducer
});

const getCandidateListPaged = createReducer(defaultState, {
  [actions.CANDIDATE_LISTING_PAGED]: isLoadingStateReducer,
  [actions.CANDIDATE_LISTING_PAGED_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_LISTING_PAGED_ERROR]: errorStateReducer,
  [actions.CANDIDATE_LISTING_PAGED_CLEAR]: resetStateReducer
});

const getCandidatesInviteApp = createReducer(defaultState, {
  [actions.CANDIDATE_INVITE_APP]: isLoadingStateReducer,
  [actions.CANDIDATE_INVITE_APP_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_INVITE_APP_ERROR]: errorStateReducer,
  [actions.CANDIDATE_INVITE_APP_CLEAR]: resetStateReducer
});

const inviteESignCandidates = createReducer(defaultState, {
  [actions.INVITE_E_SIGN_CANDIDATES]: isLoadingStateReducer,
  [actions.INVITE_E_SIGN_CANDIDATES_SUCCESS]: successStateReducer,
  [actions.INVITE_E_SIGN_CANDIDATES_ERROR]: errorStateReducer,
  [actions.INVITE_E_SIGN_CANDIDATES_CLEAR]: resetStateReducer
});

const getSiteList = createReducer(defaultState, {
  [actions.SITE_LIST]: isLoadingStateReducer,
  [actions.SITE_LIST_SUCCESS]: successStateReducer,
  [actions.SITE_LIST_ERROR]: errorStateReducer,
  [actions.SITE_LIST_CLEAR]: resetStateReducer
});
const getUserList = createReducer(defaultState, {
  [actions.USER_LIST]: isLoadingStateReducer,
  [actions.USER_LIST_SUCCESS]: successStateReducer,
  [actions.USER_LIST_ERROR]: errorStateReducer,
  [actions.USER_LIST_CLEAR]: resetStateReducer
});

const getUserListPaged = createReducer(defaultState, {
  [actions.USER_LIST_PAGED]: isLoadingStateReducer,
  [actions.USER_LIST_PAGED_SUCCESS]: successStateReducer,
  [actions.USER_LIST_PAGED_ERROR]: errorStateReducer,
  [actions.USER_LIST_PAGED_CLEAR]: resetStateReducer
});

const massResetPW = createReducer(defaultState, {
  [actions.MASS_RESET_PW]: isLoadingStateReducer,
  [actions.MASS_RESET_PW_SUCCESS]: successStateReducer,
  [actions.MASS_RESET_PW_ERROR]: errorStateReducer,
  [actions.MASS_RESET_PW_CLEAR]: resetStateReducer
});

const getClientList = createReducer(defaultState, {
  [actions.CLIENT_LIST]: isLoadingStateReducer,
  [actions.CLIENT_LIST_SUCCESS]: successStateReducer,
  [actions.CLIENT_LIST_ERROR]: errorStateReducer,
  [actions.CLIENT_LIST_CLEAR]: resetStateReducer
});

const getAddBooking = createReducer(defaultState, {
  [actions.ADD_BOOKING]: isLoadingStateReducer,
  [actions.ADD_BOOKING_SUCCESS]: successStateReducer,
  [actions.ADD_BOOKING_ERROR]: errorStateReducer,
  [actions.ADD_BOOKING_CLEAR]: resetStateReducer
});

const getCandidateTypeList = createReducer(defaultState, {
  [actions.CANDIDATE_TYPE]: isLoadingStateReducer,
  [actions.CANDIDATE_TYPE_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_TYPE_ERROR]: errorStateReducer,
  [actions.CANDIDATE_TYPE_CLEAR]: resetStateReducer
});

const getAssignCandidateList = createReducer(defaultState, {
  [actions.CANDIDATE_ASSIGN_LIST]: isLoadingStateReducer,
  [actions.CANDIDATE_ASSIGN_LIST_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_ASSIGN_LIST_ERROR]: errorStateReducer,
  [actions.CANDIDATE_ASSIGN_LIST_CLEAR]: resetStateReducer
});

const changeCandidateStatus = createReducer(defaultState, {
  [actions.CHANGE_CANDIDATE_STATUS]: isLoadingStateReducer,
  [actions.CHANGE_CANDIDATE_STATUS_SUCCESS]: successStateReducer,
  [actions.CHANGE_CANDIDATE_STATUS_ERROR]: errorStateReducer,
  [actions.CHANGE_CANDIDATE_STATUS_CLEAR]: resetStateReducer
});

const getSupplierCandidateList = createReducer(defaultState, {
  [actions.SUPPLIER_CANDIDATE_LIST]: isLoadingStateReducer,
  [actions.SUPPLIER_CANDIDATE_LIST_SUCCESS]: successStateReducer,
  [actions.SUPPLIER_CANDIDATE_LIST_ERROR]: errorStateReducer,
  [actions.SUPPLIER_CANDIDATE_LIST_CLEAR]: resetStateReducer
});

const getWorkingTimeList = createReducer(defaultState, {
  [actions.CANDIDATE_WORKING_TIME]: isLoadingStateReducer,
  [actions.CANDIDATE_WORKING_TIME_SUCCESS]: successStateReducer,
  [actions.CANDIDATE_WORKING_TIME_ERROR]: errorStateReducer,
  [actions.CANDIDATE_WORKING_TIME_CLEAR]: resetStateReducer
});

const getDriverBookings = createReducer(defaultState, {
  [actions.DRIVER_CALENDAR]: isLoadingStateReducer,
  [actions.DRIVER_CALENDAR_SUCCESS]: successStateReducer,
  [actions.DRIVER_CALENDAR_ERROR]: errorStateReducer,
  [actions.DRIVER_CALENDAR_CLEAR]: resetStateReducer
});

const getRibbonData = createReducer(defaultState, {
  [actions.RIBBON_DATA]: isLoadingStateReducer,
  [actions.RIBBON_DATA_SUCCESS]: successStateReducer,
  [actions.RIBBON_DATA_ERROR]: errorStateReducer,
  [actions.RIBBON_DATA_CLEAR]: resetStateReducer
});

export default combineReducers({
  getCandidateList,
  inviteESignCandidates,
  getCandidateListPaged,
  getSiteList,
  getUserList,
  getClientList,
  getAddBooking,
  getCandidateTypeList,
  getDriverBookings,
  getRibbonData,
  getAssignCandidateList,
  getWorkingTimeList,
  getSupplierCandidateList,
  getCandidatesInviteApp,
  getUserListPaged,
  massResetPW,
  changeCandidateStatus
});
