/* eslint-disable no-empty-function */

import { put, call } from "redux-saga/effects";
// eslint-disable-next-line no-unused-vars
import {
  httpRequestForData,
  axiosApiRequestForMultiPartData,
  axiosApiRequestDownload
} from "../api/api";
// whenever u need to send multipart axios request || just pass "axiosApiRequestForMultiPartData" in const requestParameters = {axiosRequest:axiosApiRequestForMultiPartData}
import apiInfo from "../api/apiEndPoints";
import actions from "../actionTypes";
import { apiFailureAlert } from "../actionCreators";

export function* getAllLeadSourceList() {
  const {
    getLeadSourceList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.LEAD_SOURCE_LIST_SUCCESS,
    errorAction: actions.LEAD_SOURCE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAllInsuranceList() {
  const {
    getInsuranceCategoryList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.INSURANCE_LIST_SUCCESS,
    errorAction: actions.INSURANCE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAllNationalityList() {
  const {
    getNationalityList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.NATIONALITY_LIST_SUCCESS,
    errorAction: actions.NATIONALITY_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addNewCandidate({ data }) {
  const {
    AddNewCandidate: { endPoint, method, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_NEW_CANDIDATE_SUCCESS,
    errorAction: actions.ADD_NEW_CANDIDATE_ERROR,
    errorMessage
  };
  const result = yield call(httpRequestForData, requestParameters);
  if (result?.data?.PersonDetailResult) {
    yield put(apiFailureAlert("A Person with the specified NI Number already exists"));
  }
}

export function* addNewContactAddress({ data }) {
  const {
    AddNewAddress: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.ADD_NEW_ADDRESS_SUCCESS,
    errorAction: actions.ADD_NEW_ADDRESS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addNewAdditionalAddress({ data }) {
  const {
    AddNewAdditionalAddress: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.ADD_NEW_ADDITIONAL_ADDRESS_SUCCESS,
    errorAction: actions.ADD_NEW_ADDITIONAL_ADDRESS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addMultipleDocuments({ data }) {
  const {
    AddMultipleDocuments: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_MULTIPLE_DOCUMENTS_SUCCESS,
    errorAction: actions.ADD_MULTIPLE_DOCUMENTS_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* addLicenseInformation({ data }) {
  const {
    AddLicenseInformation: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.ADD_LICENSE_INFORMATION_SUCCESS,
    errorAction: actions.ADD_LICENSE_INFORMATION_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getDrivingLicenseCapabilityList({ data }) {
  const {
    GetDrivingLicenseCapabilityList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DRIVING_LICENSE_CAPABILITY_LIST_SUCCESS,
    errorAction: actions.DRIVING_LICENSE_CAPABILITY_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getDrivingLicenseEndorsementList({ data }) {
  const {
    GetDrivingLicenseEndorsementList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DRIVING_LICENSE_ENDORSEMENT_LIST_SUCCESS,
    errorAction: actions.DRIVING_LICENSE_ENDORSEMENT_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getDrivingLicenseCapabilityTypesList({ data }) {
  const {
    GetDrivingLicenseCapabilityTypesList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DRIVING_LICENSE_CAPABILITY_TYPES_LIST_SUCCESS,
    errorAction: actions.DRIVING_LICENSE_CAPABILITY_TYPES_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getDrivingLicenseEndorsementTypesList({ data }) {
  const {
    GetDrivingLicenseEndorsementTypesList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DRIVING_LICENSE_ENDORSEMENT_TYPES_LIST_SUCCESS,
    errorAction: actions.DRIVING_LICENSE_ENDORSEMENT_TYPES_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addInductionsBansInformation({ data }) {
  const {
    AddInductionsBans: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_INDUCTIONS_BANS_DETAILS_SUCCESS,
    errorAction: actions.ADD_INDUCTIONS_BANS_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getAllQualificationTitleList() {
  const {
    getQualificationTitleList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.QUALIFICATION_TITLE_LIST_SUCCESS,
    errorAction: actions.QUALIFICATION_TITLE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAllIdentificationNameList() {
  const {
    getIdentificationNameList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.QUALIFICATION_NAME_LIST_SUCCESS,
    errorAction: actions.QUALIFICATION_NAME_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addQualificationInformation({ data }) {
  const {
    AddQualifications: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_QUALIFICATION_DETAILS_SUCCESS,
    errorAction: actions.ADD_QUALIFICATION_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* addLicenceInformation({ data }) {
  const {
    AddLicenceAdditionalDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_LICENCE_ADDITIONAL_DETAILS_SUCCESS,
    errorAction: actions.ADD_LICENCE_ADDITIONAL_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getAllPaymentTierList() {
  const {
    getPaymentTierList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.PAYMENT_TIER_LIST_SUCCESS,
    errorAction: actions.PAYMENT_TIER_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAllSupplierList() {
  const {
    getSupplierList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SUPPLIER_LIST_SUCCESS,
    errorAction: actions.SUPPLIER_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAllServiceCompanyList() {
  const {
    getServiceCompanyList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SERVICE_COMPANY_LIST_SUCCESS,
    errorAction: actions.SERVICE_COMPANY_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAllContactTypeList() {
  const {
    getContactTypeList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CONTACT_TYPE_LIST_SUCCESS,
    errorAction: actions.CONTACT_TYPE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAllExpenseTypeList() {
  const {
    getExpenseTypeList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.EXPENSE_TYPE_LIST_SUCCESS,
    errorAction: actions.EXPENSE_TYPE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addPayrollInformation({ data }) {
  const {
    AddPayrollDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_PAYROLL_DETAILS_SUCCESS,
    errorAction: actions.ADD_PAYROLL_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addPreferencesInformation({ data }) {
  const {
    AddPreferenceDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_PREFERENCE_DETAILS_SUCCESS,
    errorAction: actions.ADD_PREFERENCE_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addWorkHistoryDetails({ data }) {
  const {
    AddWorkHistory: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_WORK_HISTORY_SUCCESS,
    errorAction: actions.ADD_WORK_HISTORY_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getSatisfactionLevelLists() {
  const {
    getSatisfactionLevelList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SATISFACTION_LEVEL_LIST_SUCCESS,
    errorAction: actions.SATISFACTION_LEVEL_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getReasonsLists() {
  const {
    getReasonForLeavingList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.REASONS_LIST_SUCCESS,
    errorAction: actions.REASONS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getNonConversionLists() {
  const {
    getNonConversionList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.NON_CONVERSION_LIST_SUCCESS,
    errorAction: actions.NON_CONVERSION_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addHumanResourceInformation({ data }) {
  const {
    AddHumanResource: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_HUMAN_RESOURCE_SUCCESS,
    errorAction: actions.ADD_HUMAN_RESOURCE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getCandidateRatingTypeLists() {
  const {
    getCandidateRatingTypeList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.RATING_TYPE_LIST_SUCCESS,
    errorAction: actions.RATING_TYPE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addCandidateRatingInfo({ data }) {
  const {
    AddCandidateRating: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.ADD_CANDIDATE_RATINGS_SUCCESS,
    errorAction: actions.ADD_CANDIDATE_RATINGS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getCandidateInfringementsTypeLists() {
  const {
    getCandidateInfringementsTypeList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.INFRINGEMENTS_TYPE_LIST_SUCCESS,
    errorAction: actions.INFRINGEMENTS_TYPE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addCandidateInfringementsInfo({ data }) {
  const {
    AddCandidateInfringements: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.targetId}&requestObject=${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.ADD_CANDIDATE_INFRINGEMENTS_SUCCESS,
    errorAction: actions.ADD_CANDIDATE_INFRINGEMENTS_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("attachFiles")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getHolidaysLists({ data }) {
  const {
    getHolidaysList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.candidateObjectId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    apiMethod: method,
    apiPayload: "",
    successAction: actions.HOLIDAYS_LIST_SUCCESS,
    errorAction: actions.HOLIDAYS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getNotesLists({ data }) {
  const {
    getNotesList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.NOTES_LIST_SUCCESS,
    errorAction: actions.NOTES_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addNotesActivityInfo({ data }) {
  const {
    AddNotesActivity: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_NOTES_ACTIVITY_SUCCESS,
    errorAction: actions.ADD_NOTES_ACTIVITY_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getStatementLists() {
  const {
    getStatementList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.STATEMENT_OPTIONS_LIST_SUCCESS,
    errorAction: actions.STATEMENT_OPTIONS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getCandidatePerformanceTypesLists() {
  const {
    getCandidatePerformanceTypesList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.PERFORMANCE_TYPE_LIST_SUCCESS,
    errorAction: actions.PERFORMANCE_TYPE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addPerformanceActivityInfo({ data }) {
  const {
    AddPerformanceActivity: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_PERFORMANCE_ACTIVITY_SUCCESS,
    errorAction: actions.ADD_PERFORMANCE_ACTIVITY_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getTasksLists({ data }) {
  const {
    getTasksList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.TASKS_LIST_SUCCESS,
    errorAction: actions.TASKS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addTasksActivityInfo({ data }) {
  const {
    AddTasksActivity: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_TASKS_ACTIVITY_SUCCESS,
    errorAction: actions.ADD_TASKS_ACTIVITY_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("filesTicket") || data.payload.has("filesNotes")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getSmsHistoryLists({ data }) {
  const {
    getSmsHistory: { method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${data.endPoint}${data.ObjectId}`,
    apiMethod: method,
    apiPayload: data.ObjectId,
    successAction: actions.SMS_HISTORY_LIST_SUCCESS,
    errorAction: actions.SMS_HISTORY_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* SendSMSInfo({ data }) {
  const {
    SendSMS: { method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${data.endPoint}${data.ObjectId}&smsContent=${encodeURIComponent(data.smsContent)}`,
    apiMethod: method,
    // apiPayload: data,
    successAction: actions.SEND_SMS_SUCCESS,
    errorAction: actions.SEND_SMS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getEmailHistoryLists({ data }) {
  const {
    getEmailHistory: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.EMAIL_HISTORY_LIST_SUCCESS,
    errorAction: actions.EMAIL_HISTORY_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* AddSmartBoxActivityInfo({ data }) {
  const {
    AddSmartBoxActivity: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_SMART_BOX_ACTIVITY_SUCCESS,
    errorAction: actions.ADD_SMART_BOX_ACTIVITY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getRatingsDetails({ data }) {
  const {
    getRatingsDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.RATING_DETAILS_LIST_SUCCESS,
    errorAction: actions.RATING_DETAILS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getInfringementsDetails({ data }) {
  const {
    getInfringementsDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.INFRINGEMENTS_DETAILS_LIST_SUCCESS,
    errorAction: actions.INFRINGEMENTS_DETAILS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getPerformanceDetails({ data }) {
  const {
    getPerformanceDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.PERFORMANCE_DETAILS_LIST_SUCCESS,
    errorAction: actions.PERFORMANCE_DETAILS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getSmartBoxDetailsInfo({ data }) {
  const {
    getSmartBoxDetailsList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SMART_BOX_DETAILS_LIST_SUCCESS,
    errorAction: actions.SMART_BOX_DETAILS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateCandidateRatingInfo({ data }) {
  const {
    updateCandidateRating: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.candidateObjectId}&requestObject=${data.requestObject}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_CANDIDATE_RATING_SUCCESS,
    errorAction: actions.UPDATE_CANDIDATE_RATING_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateCandidateInfringementInfo({ data }) {
  const {
    updateCandidateInfringement: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.targetId}&requestObject=${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.UPDATE_CANDIDATE_INFRINGEMENT_SUCCESS,
    errorAction: actions.UPDATE_CANDIDATE_INFRINGEMENT_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("attachFiles")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* updateCandidatePerformanceInfo({ data }) {
  const {
    updateCandidatePerformance: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.UPDATE_CANDIDATE_PERFORMANCE_SUCCESS,
    errorAction: actions.UPDATE_CANDIDATE_PERFORMANCE_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files") || data.payload.has("attachFiles")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* updateCandidateNotesInfo({ data }) {
  const {
    updateCandidateNotes: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.UPDATE_CANDIDATE_NOTES_SUCCESS,
    errorAction: actions.UPDATE_CANDIDATE_NOTES_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* updateCandidateSmartBoxInfo({ data }) {
  const {
    updateCandidateSmartBox: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_CANDIDATE_SMART_BOX_SUCCESS,
    errorAction: actions.UPDATE_CANDIDATE_SMART_BOX_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getSearchUserListInfo({ data }) {
  const {
    getSearchUserList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SEARCH_USER_LIST_SUCCESS,
    errorAction: actions.SEARCH_USER_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteCandidateRatingInfo({ data }) {
  const {
    deleteCandidateRating: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_CANDIDATE_RATING_SUCCESS,
    errorAction: actions.DELETE_CANDIDATE_RATING_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteCandidateInfringementsInfo({ data }) {
  const {
    deleteCandidateInfringements: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_CANDIDATE_INFRINGEMENTS_SUCCESS,
    errorAction: actions.DELETE_CANDIDATE_INFRINGEMENTS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteCandidateNotesInfo({ data }) {
  const {
    deleteCandidateNotes: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_CANDIDATE_NOTES_SUCCESS,
    errorAction: actions.DELETE_CANDIDATE_NOTES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteCandidateTicketsInfo({ data }) {
  const {
    deleteCandidateTickets: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_CANDIDATE_TICKETS_SUCCESS,
    errorAction: actions.DELETE_CANDIDATE_TICKETS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteCandidatePerformanceInfo({ data }) {
  const {
    deleteCandidatePerformance: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_CANDIDATE_PERFORMANCE_SUCCESS,
    errorAction: actions.DELETE_CANDIDATE_PERFORMANCE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateCandidateTicketsInfo({ data }) {
  const {
    updateCandidateTickets: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.UPDATE_CANDIDATE_TICKETS_SUCCESS,
    errorAction: actions.UPDATE_CANDIDATE_TICKETS_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("filesTicket") || data.payload.has("filesNotes")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getTagsLists() {
  const {
    getTagsListDetails: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.TAGS_LIST_SUCCESS,
    errorAction: actions.TAGS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getTagsListsV2() {
  const {
    getTagsListV2Details: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.TAGS_LIST_V2_SUCCESS,
    errorAction: actions.TAGS_LIST_V2_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addTagsListDetailsInfo({ data }) {
  const {
    addTagsListDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_TAGS_LIST_SUCCESS,
    errorAction: actions.ADD_TAGS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* candidateDetailsInfo({ data }) {
  const {
    getCandidateDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.personObjectId}&candidateObjectId=${data.candidateObjectId}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CANDIDATE_DETAILS_SUCCESS,
    errorAction: actions.CANDIDATE_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateCandidateDetails({ data }) {
  const {
    updateCandidateDetails: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_CANDIDATE_DETAILS_SUCCESS,
    errorAction: actions.UPDATE_CANDIDATE_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getContactDetailsInfo({ data }) {
  const {
    getContactDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CONTACT_DETAILS_SUCCESS,
    errorAction: actions.CONTACT_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateContactDetails({ data }) {
  const {
    updateContactDetails: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.UPDATE_CONTACT_DETAILS_SUCCESS,
    errorAction: actions.UPDATE_CONTACT_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAdditionalContacts({ data }) {
  const {
    getAdditionalContacts: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADDITIONAL_CONTACT_DETAILS_SUCCESS,
    errorAction: actions.ADDITIONAL_CONTACT_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateAdditionalContacts({ data }) {
  const {
    updateAdditionalContacts: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.UPDATE_ADDITIONAL_CONTACT_SUCCESS,
    errorAction: actions.UPDATE_ADDITIONAL_CONTACT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getWorkHistoryList({ data }) {
  const {
    getWorkHistoryList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_WORK_HISTORY_DETAILS_SUCCESS,
    errorAction: actions.GET_WORK_HISTORY_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getStartEndDates({ data }) {
  const {
    getStartEndDates: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_START_END_DATES_SUCCESS,
    errorAction: actions.GET_START_END_DATES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteAddressInfo({ data }) {
  const {
    deleteAddressInfo: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_ADDRESS_DETAILS_SUCCESS,
    errorAction: actions.DELETE_ADDRESS_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateWorkHistory({ data }) {
  const {
    updateWorkHistory: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.UPDATE_WORK_HISTORY_SUCCESS,
    errorAction: actions.UPDATE_WORK_HISTORY_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getInductionAndBanDetails({ data }) {
  const {
    getInductionAndBanDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_INDUCTIONS_BANS_LIST_SUCCESS,
    errorAction: actions.GET_INDUCTIONS_BANS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateInductionAndBan({ data }) {
  const {
    updateInductionAndBan: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.UPDATE_INDUCTIONS_BANS_SUCCESS,
    errorAction: actions.UPDATE_INDUCTIONS_BANS_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* deleteBanDetails({ data }) {
  const {
    deleteBanDetails: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_BANS_DETAILS_SUCCESS,
    errorAction: actions.DELETE_BANS_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteInductionDetails({ data }) {
  const {
    deleteInductionDetails: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_INDUCTIONS_DETAILS_SUCCESS,
    errorAction: actions.DELETE_INDUCTIONS_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getPersonTimePreference({ data }) {
  const {
    getPersonTimePreference: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_TIME_PREFERENCE_SUCCESS,
    errorAction: actions.GET_TIME_PREFERENCE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getPayrollInformation({ data }) {
  const {
    getPayrollInformation: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_PAYROLL_DETAILS_SUCCESS,
    errorAction: actions.GET_PAYROLL_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* isCandidateSts({ data }) {
  const {
    getIsCandidateSts: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_IS_CANDIDATE_STS_SUCCESS,
    errorAction: actions.GET_IS_CANDIDATE_STS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updatePayrollInformation({ data }) {
  const {
    updatePayrollInformation: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.UPDATE_PAYROLL_DETAILS_SUCCESS,
    errorAction: actions.UPDATE_PAYROLL_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addDrivingLicense({ data }) {
  const {
    addDrivingLicense: { endPoint, method, errorMessage, successMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_DRIVING_LICENSE_SUCCESS,
    errorAction: actions.ADD_DRIVING_LICENSE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getDrivingLicense({ data }) {
  const {
    getDrivingLicense: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_DRIVING_LICENSE_SUCCESS,
    errorAction: actions.GET_DRIVING_LICENSE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getHumanResource({ data }) {
  const {
    getHumanResource: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_HUMAN_RESOURCE_SUCCESS,
    errorAction: actions.GET_HUMAN_RESOURCE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateHumanResource({ data }) {
  const {
    updateHumanResource: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.UPDATE_HUMAN_RESOURCE_SUCCESS,
    errorAction: actions.UPDATE_HUMAN_RESOURCE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addLicenceCapabilities({ data }) {
  const {
    addLicenceCapabilities: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_LICENCE_CAPABILITIES_SUCCESS,
    errorAction: actions.ADD_LICENCE_CAPABILITIES_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* updateLicenceCapabilities({ data }) {
  const {
    updateLicenceCapabilities: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.UPDATE_LICENCE_CAPABILITIES_SUCCESS,
    errorAction: actions.UPDATE_LICENCE_CAPABILITIES_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* addDrivingLicenseEndorsement({ data }) {
  const {
    addDrivingLicenseEndorsement: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_LICENCE_ENDORSEMENTS_SUCCESS,
    errorAction: actions.ADD_LICENCE_ENDORSEMENTS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateDrivingLicenseEndorsement({ data }) {
  const {
    updateDrivingLicenseEndorsement: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.UPDATE_LICENCE_ENDORSEMENTS_SUCCESS,
    errorAction: actions.UPDATE_LICENCE_ENDORSEMENTS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteDrivingLicenseEndorsement({ data }) {
  const {
    deleteDrivingLicenseEndorsement: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.DELETE_LICENCE_ENDORSEMENTS_SUCCESS,
    errorAction: actions.DELETE_LICENCE_ENDORSEMENTS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteWorkHistory({ data }) {
  const {
    deleteWorkHistory: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.personObjectId}
    &workHistoryObjectId=${data.workHistoryObjectId}`,
    apiMethod: method,
    apiPayload: data,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.DELETE_WORK_HISTORY_SUCCESS,
    errorAction: actions.DELETE_WORK_HISTORY_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteStartEndDate({ data }) {
  const {
    deleteStartEndDate: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_START_END_DATE_SUCCESS,
    errorAction: actions.DELETE_START_END_DATE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateStartEndDate({ data }) {
  const {
    updateStartEndDate: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.UPDATE_START_END_DATE_SUCCESS,
    errorAction: actions.UPDATE_START_END_DATE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteDrivingLicenseCapabilities({ data }) {
  const {
    deleteDrivingLicenseCapabilities: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.DELETE_LICENCE_CAPABILITIES_SUCCESS,
    errorAction: actions.DELETE_LICENCE_CAPABILITIES_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addDigiCard({ data }) {
  const {
    addDigiCard: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.ADD_DIGI_CARD_SUCCESS,
    errorAction: actions.ADD_DIGI_CARD_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* deleteDigiCard({ data }) {
  const {
    deleteDigiCard: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.DELETE_DIGI_CARD_SUCCESS,
    errorAction: actions.DELETE_DIGI_CARD_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateDigiCard({ data }) {
  const {
    updateDigiCard: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.UPDATE_DIGI_CARD_SUCCESS,
    errorAction: actions.UPDATE_DIGI_CARD_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getDigiCard({ data }) {
  const {
    getDigiCard: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_DIGI_CARD_SUCCESS,
    errorAction: actions.GET_DIGI_CARD_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getQualificationIdentification({ data }) {
  const {
    getQualificationIdentification: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_QUALIFICATION_DETAILS_SUCCESS,
    errorAction: actions.GET_QUALIFICATION_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteQualificationIdentification({ data }) {
  const {
    deleteQualificationIdentification: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.DELETE_QUALIFICATION_DETAILS_SUCCESS,
    errorAction: actions.DELETE_QUALIFICATION_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* updateQualificationIdentification({ data }) {
  const {
    updateQualificationIdentification: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.params}`,
    apiMethod: method,
    apiPayload: data.payload,
    // axiosRequest: axiosApiRequestForMultiPartData,
    successAction: actions.UPDATE_QUALIFICATION_DETAILS_SUCCESS,
    errorAction: actions.UPDATE_QUALIFICATION_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  if (data.payload.has("files")) {
    requestParameters.axiosRequest = axiosApiRequestForMultiPartData;
  }
  yield call(httpRequestForData, requestParameters);
}

export function* getRemindersLists({ data }) {
  const {
    getRemindersList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.REMINDERS_LIST_SUCCESS,
    errorAction: actions.REMINDERS_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteReminder({ data }) {
  const {
    deleteReminder: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_REMINDER_SUCCESS,
    errorAction: actions.DELETE_REMINDER_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAssignedTickets({ data }) {
  const {
    getAssignedTickets: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_ASSIGNED_TICKETS_SUCCESS,
    errorAction: actions.GET_ASSIGNED_TICKETS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getPayeStatementList() {
  const {
    getPayeStatementList: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_PAYE_LIST_SUCCESS,
    errorAction: actions.GET_PAYE_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteUserTags({ data }) {
  const {
    deleteUserTags: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.TargetObjectIds}&TagName=${data.TagName}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_USER_TAGS_SUCCESS,
    errorAction: actions.DELETE_USER_TAGS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* listAttachments({ data }) {
  const {
    listAttachments: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.LIST_ATTACHMENTS_SUCCESS,
    errorAction: actions.LIST_ATTACHMENTS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* downloadDocument({ data }) {
  const {
    downloadDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DOWNLOAD_DOCUMENT_SUCCESS,
    errorAction: actions.DOWNLOAD_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  requestParameters.axiosRequest = axiosApiRequestDownload;
  yield call(httpRequestForData, requestParameters);
}

export function* deleteMultiDocument({ data }) {
  const {
    deleteMultiDocument: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_DOCUMENT_SUCCESS,
    errorAction: actions.DELETE_DOCUMENT_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteSmartBoxMessage({ data }) {
  const {
    deleteSmartBoxMessage: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.candidateObjectId || data.personObjectId}&smartBoxMessageObjectId=${
      data.targetId
    }`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_SMART_BOX_SUCCESS,
    errorAction: actions.DELETE_SMART_BOX_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* refreshMetaCache() {
  const {
    refreshMetaCache: { endPoint, method, data, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: endPoint,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.REFRESH_META_CACHE_SUCCESS,
    errorAction: actions.REFRESH_META_CACHE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* sendAppMessage({ data }) {
  const {
    sendAppMessage: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SEND_APP_SMS_SUCCESS,
    errorAction: actions.SEND_APP_SMS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAppHistory({ data }) {
  const {
    getAppHistory: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.APP_HISTORY_LIST_SUCCESS,
    errorAction: actions.APP_HISTORY_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* appPersonRegister({ data }) {
  const {
    appPersonRegister: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.REGISTER_APP_PERSON_SUCCESS,
    errorAction: actions.REGISTER_APP_PERSON_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* detailPersonSearch({ data }) {
  const {
    detailPersonSearch: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DETAIL_PERSON_SEARCH_SUCCESS,
    errorAction: actions.DETAIL_PERSON_SEARCH_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* sendBulkSMS({ data }) {
  const { smsContent, AttemptAppMessage = "", personObjectIds } = data;
  const {
    sendBulkSMS: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?smsContent=${smsContent}&AttemptAppMessage=${AttemptAppMessage}`,
    apiMethod: method,
    apiPayload: personObjectIds,
    successAction: actions.SEND_BULK_SMS_SUCCESS,
    errorAction: actions.SEND_BULK_SMS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* sendSMSToPhoneNumber({ data }) {
  const {
    sendSMSToPhoneNumber: { endPoint, method, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${encodeURIComponent(data?.Content)}`,
    apiMethod: method,
    apiPayload: data?.phoneNumber,
    successAction: actions.SEND_SMS_TO_PHONE_NUMBER_SUCCESS,
    errorAction: actions.SEND_SMS_TO_PHONE_NUMBER_ERROR,
    successMessage: "Message Sent successfully",
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* sendAvailabilityRequest({ data }) {
  const {
    sendAvailabilityRequest: { endPoint, method, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.personObjectId}&getTemplateOnly=${data.template}`,
    apiMethod: method,
    apiPayload: data.personObjectId,
    successAction: actions.SEND_AVAILABILITY_REQUEST_SUCCESS,
    errorAction: actions.SEND_AVAILABILITY_REQUEST_ERROR,
    successMessage: !data.template ? "Message Sent successfully" : "",
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* sendCalendarUpdated({ data }) {
  const {
    sendCalendarUpdated: { endPoint, method, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.personObjectId}&getTemplateOnly=${data.template}`,
    apiMethod: method,
    apiPayload: data.personObjectId,
    successAction: actions.SEND_CALENDAR_UPDATED_SUCCESS,
    errorAction: actions.SEND_CALENDAR_UPDATED_ERROR,
    successMessage: !data.template ? "Message Sent successfully" : "",
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* sendNoWorkTomorrow({ data }) {
  const {
    sendNoWorkTomorrow: { endPoint, method, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data.personObjectId}&getTemplateOnly=${data.template}`,
    apiMethod: method,
    apiPayload: data.personObjectId,
    successAction: actions.SEND_NO_WORK_TOMORROW_SUCCESS,
    errorAction: actions.SEND_NO_WORK_TOMORROW_ERROR,
    successMessage: !data.template ? "Message Sent successfully" : "",
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* sendCalendarSummary({ data }) {
  const {
    sendCalendarSummary: { endPoint, method, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SEND_CALENDAR_SUMMARY_SUCCESS,
    errorAction: actions.SEND_CALENDAR_SUMMARY_ERROR,
    successMessage: !data.GetTemplateOnly ? "Message Sent successfully" : "",
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* sendBookingDetails({ data }) {
  const {
    sendBookingDetails: { endPoint, method, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SEND_BOOKING_DETAILS_SUCCESS,
    errorAction: actions.SEND_BOOKING_DETAILS_ERROR,
    successMessage: !data.GetTemplateOnly ? "Message Sent successfully" : "",
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
export function* sendBookingHoursQuery({ data }) {
  const {
    sendBookingHoursQuery: { endPoint, method, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.SEND_BOOKING_HRS_SUCCESS,
    errorAction: actions.SEND_BOOKING_HRS_ERROR,
    successMessage: !data.GetTemplateOnly ? "Message Sent successfully" : "",
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getCandidateLinks({ data }) {
  const {
    getCandidateLinks: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.GET_CANDIDATE_LINKS_SUCCESS,
    errorAction: actions.GET_CANDIDATE_LINKS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAccruedHolidayAdjustmentsList({ data }) {
  const {
    getAccruedHolidayAdjustmentsList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.HOLIDAYS_ADJUSTMENT_LIST_SUCCESS,
    errorAction: actions.HOLIDAYS_ADJUSTMENT_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getHolidayShiftsAdjustmentsList({ data }) {
  const {
    getHolidayShiftsAdjustmentsList: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.HOLIDAYS_SHIFT_LIST_SUCCESS,
    errorAction: actions.HOLIDAYS_SHIFT_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addAccruedHolidayAdjustments({ data }) {
  const {
    addAccruedHolidayAdjustments: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_HOLIDAYS_ADJUSTMENT_LIST_SUCCESS,
    errorAction: actions.ADD_HOLIDAYS_ADJUSTMENT_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addHolidayShiftsAdjustments({ data }) {
  const {
    addHolidayShiftsAdjustments: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.ADD_HOLIDAYS_SHIFT_LIST_SUCCESS,
    errorAction: actions.ADD_HOLIDAYS_SHIFT_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteAccruedHolidayAdjustments({ data }) {
  const {
    deleteAccruedHolidayAdjustments: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_HOLIDAYS_ADJUSTMENT_LIST_SUCCESS,
    errorAction: actions.DELETE_HOLIDAYS_ADJUSTMENT_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* deleteHolidayShiftsAdjustments({ data }) {
  const {
    deleteHolidayShiftsAdjustments: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DELETE_HOLIDAYS_SHIFT_LIST_SUCCESS,
    errorAction: actions.DELETE_HOLIDAYS_SHIFT_LIST_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getRtwCheck({ data }) {
  const {
    getRtwCheck: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}${data}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.REQUEST_RTW_CHECK_SUCCESS,
    errorAction: actions.REQUEST_RTW_CHECK_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getHolidayPeriod({ data }) {
  const {
    getHolidayPeriod: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.HOLIDAY_PERIOD_SUCCESS,
    errorAction: actions.HOLIDAY_PERIOD_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* createDetailedBookings({ data }) {
  const {
    createDetailedBookings: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.DETAILED_BOOKINGS_SUCCESS,
    errorAction: actions.DETAILED_BOOKINGS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getCandidateDefaultExpense({ data }) {
  const {
    getCandidateDefaultExpense: { method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${data?.url}`,
    apiMethod: method,
    apiPayload: data,
    successAction: actions.CANDIDATE_DEFAULT_EXPENSE_SUCCESS,
    errorAction: actions.CANDIDATE_DEFAULT_EXPENSE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* candidateClientDefaultExpense({ data }) {
  const {
    candidateClientDefaultExpense: { endPoint, method }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}`,
    apiMethod: method,
    apiPayload: data.payload,
    successAction: actions.ACTION_CANDIDATE_DEFAULT_EXPENSE_SUCCESS,
    errorAction: actions.ACTION_CANDIDATE_DEFAULT_EXPENSE_ERROR,
    successMessage: data.sMessage,
    errorMessage: data.eMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* setPassword({ payload }) {
  const {
    setNewPassword: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?PersonObjectId=${payload?.ObjectId}&NewPassword=${payload.password}`,
    apiMethod: method,
    successAction: actions.PASSWORD_SETUP_SUCCESS,
    errorAction: actions.PASSWORD_SETUP_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* getAppDetails({ payload }) {
  const {
    MRAppDetails: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?PersonObjectId=${payload?.ObjectId}`,
    apiMethod: method,
    successAction: actions.GET_APP_DETAILS_SUCCESS,
    errorAction: actions.GET_APP_DETAILS_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* appClaimID({ payload }) {
  const {
    claimAPPId: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?PersonObjectId=${payload?.ObjectId}`,
    apiMethod: method,
    successAction: actions.CLAIM_APP_ID_SUCCESS,
    errorAction: actions.CLAIM_APP_ID_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* clearAppProfile({ payload }) {
  const {
    clearAppProfile: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?PersonObjectId=${payload?.ObjectId}`,
    apiMethod: method,
    successAction: actions.CLEAR_APP_PROFILE_SUCCESS,
    errorAction: actions.CLEAR_APP_PROFILE_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}

export function* addPersonToLBapi({ payload }) {
  const {
    addPersonToLBAPI: { endPoint, method, successMessage, errorMessage }
  } = apiInfo;
  const requestParameters = {
    url: `${endPoint}?personObjectId=${payload}`,
    apiMethod: method,
    successAction: actions.ADD_PERSON_TO_LBAPI_SUCCESS,
    errorAction: actions.ADD_PERSON_TO_LBAPI_ERROR,
    successMessage,
    errorMessage
  };
  yield call(httpRequestForData, requestParameters);
}
