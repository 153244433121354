import { all, fork } from "redux-saga/effects";

import * as candidateWatcher from "../watchers/candidateWatcher";
import * as candidateModalWatcher from "../watchers/candidateModalWatcher";
import * as driverWatcher from "../watchers/driverWatcher";
import * as calendarWatcher from "../watchers/calendarWatcher";
import * as siteModalWatcher from "../watchers/siteModalWatcher";
import * as siteWatcher from "../watchers/siteWatcher";
import * as userWatcher from "../watchers/userWatcher ";
import * as clientWatcher from "../watchers/clientWatcher";
import * as bookingWatcher from "../watchers/bookingWatcher";
import * as financeWatcher from "../watchers/financeWatcher";
import * as bookingSetupWatcher from "../watchers/bookingSetupWatcher";
import * as supplierWatcher from "../watchers/supplierWatcher";
import * as reportWatcher from "../watchers/reportWatcher";

export default function* RootSaga() {
  yield all([
    fork(candidateWatcher.watchChangeCandidateStatus),
    fork(candidateWatcher.watchGetCandidateList),
    fork(candidateWatcher.watchGetCandidateListPaged),
    fork(candidateWatcher.watchGetCandidateInviteApp),
    fork(candidateWatcher.watchInviteESignCandidates),
    fork(candidateWatcher.watchGetAssignCandidateList),
    fork(candidateWatcher.watchGetSupplierCandidateList),
    fork(candidateWatcher.watchGetWorkingTimeSummary),
    fork(candidateWatcher.watchAddBooking),
    fork(candidateWatcher.watchGetCandidateTypeList),
    fork(candidateWatcher.watchGetClientList),
    fork(candidateWatcher.watchGetSiteList),
    fork(candidateWatcher.watchDriverCalendarEvents),
    fork(candidateWatcher.watchRibbonData),
    fork(candidateWatcher.watchAddCandidateImport),
    fork(candidateWatcher.watchAddHoursWorkedImport),
    fork(candidateWatcher.watchUpdatePersonNote),
    fork(calendarWatcher.watchGetDailyBookingList),
    fork(calendarWatcher.watchGetMonthlyBookingList),
    fork(calendarWatcher.watchGetWeeklyBookingList),
    fork(candidateModalWatcher.watchGetCandidateDuplicates),
    fork(candidateModalWatcher.watchSendEmail),
    fork(candidateModalWatcher.watchListEmail),
    fork(candidateModalWatcher.watchListEmails),
    fork(candidateModalWatcher.watchDeleteCandidates),
    fork(candidateModalWatcher.watchDeletePerson),
    fork(candidateModalWatcher.watchMergePerson),
    fork(candidateModalWatcher.watchGlobalSearch),
    fork(candidateModalWatcher.watchAttachCandidates),
    fork(candidateModalWatcher.watchMergeAndDeleteCandidates),
    fork(candidateModalWatcher.watchAddAvailability),
    fork(candidateModalWatcher.watchAddTimeOff),
    fork(candidateModalWatcher.watchRemoveAvailability),
    fork(candidateModalWatcher.watchDeleteTimeOff),
    fork(candidateModalWatcher.watchGetBookingDetails),
    fork(driverWatcher.watchGetLeadSourceList),
    fork(driverWatcher.watchGetInsuranceList),
    fork(driverWatcher.watchGetNationalityList),
    fork(driverWatcher.watchAddNewCandidate),
    fork(driverWatcher.watchAddNewAddress),
    fork(driverWatcher.watchAddNewAdditionalAddress),
    fork(driverWatcher.watchAddMultipleDocuments),
    fork(driverWatcher.watchAddLicenseInformation),
    fork(driverWatcher.watchGetDrivingLicenseCapabilityList),
    fork(driverWatcher.watchGetDrivingLicenseEndorsementList),
    fork(driverWatcher.watchGetDrivingLicenseCapabilityTypesList),
    fork(driverWatcher.watchGetDrivingLicenseEndorsementTypesList),
    fork(driverWatcher.watchAddInductionsBansInformation),
    fork(driverWatcher.watchGetAllQualificationTitleList),
    fork(driverWatcher.watchGetAllIdentificationNameList),
    fork(driverWatcher.watchAddQualificationInformation),
    fork(driverWatcher.watchAddLicenceInformation),
    fork(driverWatcher.watchGetAllPaymentTierList),
    fork(driverWatcher.watchGetAllSupplierList),
    fork(driverWatcher.watchGetAllServiceCompanyList),
    fork(driverWatcher.watchGetAllContactTypeList),
    fork(driverWatcher.watchGetAllExpenseTypeList),
    fork(driverWatcher.watchAddPayrollInformation),
    fork(driverWatcher.watchAddPreferencesInformation),
    fork(driverWatcher.watchAddWorkHistoryDetails),
    fork(driverWatcher.watchGetSatisfactionLevelList),
    fork(driverWatcher.watchGetReasonsLists),
    fork(driverWatcher.watchGetNonConversionLists),
    fork(driverWatcher.watchAddHumanResourceInformation),
    fork(driverWatcher.watchGetCandidateRatingTypeLists),
    fork(driverWatcher.watchAddCandidateRatingInfo),
    fork(driverWatcher.watchGetCandidateInfringementsTypeLists),
    fork(driverWatcher.watchAddCandidateInfringementsInfo),
    fork(driverWatcher.watchGetHolidaysLists),
    fork(driverWatcher.watchGetNotesLists),
    fork(driverWatcher.watchAddNotesActivityInfo),
    fork(driverWatcher.watchGetStatementLists),
    fork(driverWatcher.watchCandidatePerformanceTypesLists),
    fork(driverWatcher.watchAddPerformanceActivityInfo),
    fork(candidateModalWatcher.watchBookingCalendarOperations),
    fork(candidateModalWatcher.watchUpdateBookingDetailsData),
    fork(candidateModalWatcher.watchConfirmAllBookingOperation),
    fork(siteModalWatcher.watchListEmail),
    fork(siteModalWatcher.watchSendEmail),
    fork(siteModalWatcher.watchlistPhoneNumer),
    fork(siteModalWatcher.watchsendSMS),
    fork(driverWatcher.watchGetTasksLists),
    fork(driverWatcher.watchAddTasksActivityInfo),
    fork(driverWatcher.watchGetSmsHistoryLists),
    fork(driverWatcher.watchSendSMSInfo),
    fork(driverWatcher.watchGetEmailHistoryLists),
    fork(driverWatcher.watchAddSmartBoxActivityInfo),
    fork(siteModalWatcher.WatchgetLocation),
    fork(siteWatcher.watchDaysOfWeeks),
    fork(siteWatcher.watchAddSiteDetails),
    fork(siteWatcher.watchSiteShiftDetails),
    fork(siteWatcher.watchSiteDocumentType),
    fork(siteWatcher.watchAddSiteDocument),
    fork(driverWatcher.watchGetRatingsDetails),
    fork(driverWatcher.watchGetInfringementsDetails),
    fork(driverWatcher.watchGetPerformanceDetails),
    fork(driverWatcher.watchGetSmartBoxDetailsInfo),
    fork(driverWatcher.watchUpdateCandidateRatingInfo),
    fork(driverWatcher.watchUpdateCandidateInfringementInfo),
    fork(driverWatcher.watchUpdateCandidatePerformanceInfo),
    fork(driverWatcher.watchUpdateCandidateNotesInfo),
    fork(driverWatcher.watchUpdateCandidateSmartBoxInfo),
    fork(driverWatcher.watchGetSearchUserListInfo),
    fork(siteWatcher.watchAddContactDetails),
    fork(siteWatcher.watchSupplierList),
    fork(driverWatcher.watchDeleteCandidateRatingInfo),
    fork(driverWatcher.watchDeleteCandidateInfringementsInfo),
    fork(driverWatcher.watchDeleteCandidateNotesInfo),
    fork(driverWatcher.watchDeleteCandidateTicketsInfo),
    fork(driverWatcher.watchUpdateCandidateTicketsInfo),
    fork(driverWatcher.watchDeleteCandidatePerformanceInfo),
    fork(siteModalWatcher.watchAddBookingRule),
    fork(siteWatcher.watchSaveAwrDetails),
    fork(siteWatcher.watchRequirementList),
    fork(siteWatcher.watchSaveBookingRule),
    fork(siteWatcher.watchDefaultAwrRule),
    fork(driverWatcher.watchGetTagsListsV2Info),
    fork(driverWatcher.watchGetTagsListsInfo),
    fork(driverWatcher.watchAddTagsListDetailsInfo),
    fork(siteWatcher.watchUpdateSiteContact),
    fork(siteWatcher.watchGetSiteDetails),
    fork(siteWatcher.watchUpdateGeneralDetails),
    fork(siteWatcher.watchAddClientShiftTypeData),
    fork(siteWatcher.watchUpdateClientShiftTypeData),
    fork(siteWatcher.watchDeleteClientShiftTypeData),
    fork(siteWatcher.watchGetContactDetails),
    fork(driverWatcher.watchCandidateDetailsInfo),
    fork(driverWatcher.watchUpdateCandidateDetails),
    fork(driverWatcher.watchGetContactDetailsInfo),
    fork(driverWatcher.watchUpdateContactDetails),
    fork(driverWatcher.watchGetAdditionalContacts),
    fork(driverWatcher.watchUpdateAdditionalContacts),
    fork(driverWatcher.watchGetWorkHistoryList),
    fork(driverWatcher.watchGetStartEndDates),
    fork(driverWatcher.watchDeleteAddressInfo),
    fork(driverWatcher.watchUpdateWorkHistory),
    fork(driverWatcher.watchGetInductionAndBanDetails),
    fork(driverWatcher.watchUpdateInductionAndBan),
    fork(driverWatcher.watchDeleteInductionDetails),
    fork(driverWatcher.watchDeleteBanDetails),
    fork(siteWatcher.watchDeleteAWR),
    fork(siteWatcher.watchCopyAWR),
    fork(siteWatcher.watchGetBookingRule),
    fork(siteWatcher.watchGetResourceRequirement),
    fork(siteWatcher.watchSaveResourceRequirement),
    fork(siteWatcher.watchUpdateResourceRequirement),
    fork(siteWatcher.watchDeleteResourceRequirement),
    fork(siteWatcher.watchGetSitePipelineCandidates),
    fork(siteWatcher.watchGetSiteInfringement),
    fork(siteWatcher.watchGetSiteCandidatePerformance),
    fork(driverWatcher.watchGetPersonTimePreference),
    fork(driverWatcher.watchGetPayrollInformation),
    fork(driverWatcher.watchGetIsCandidateSts),
    fork(driverWatcher.watchUpdatePayrollInformation),
    fork(driverWatcher.watchAddDrivingLicense),
    fork(driverWatcher.watchGetDrivingLicense),
    fork(driverWatcher.watchGetHumanResource),
    fork(driverWatcher.watchUpdateHumanResource),
    fork(driverWatcher.watchAddLicenceCapabilities),
    fork(driverWatcher.watchUpdateLicenceCapabilities),
    fork(driverWatcher.watchAddDrivingLicenseEndorsement),
    fork(driverWatcher.watchUpdateDrivingLicenseEndorsement),
    fork(driverWatcher.watchDeleteDrivingLicenseEndorsement),
    fork(driverWatcher.watchDeleteWorkHistory),
    fork(driverWatcher.watchDeleteStartEndDate),
    fork(driverWatcher.watchUpdateStartEndDate),
    fork(driverWatcher.watchDeleteDrivingLicenseCapabilities),
    fork(driverWatcher.watchAddDigiCard),
    fork(userWatcher.getUserListdetails),
    fork(userWatcher.watchAddNewUserInfo),
    fork(userWatcher.watchGetUserListDetailsPaged),
    fork(userWatcher.watchUserMassResetPW),
    fork(userWatcher.watchDeleteUserInfo),
    fork(userWatcher.watchChangePasswordInfo),
    fork(userWatcher.watchEditUserInfo),
    fork(clientWatcher.watchCostReport),
    fork(clientWatcher.watchKPIReport),
    fork(driverWatcher.watchDeleteDigiCard),
    fork(driverWatcher.watchUpdateDigiCard),
    fork(driverWatcher.watchGetDigiCard),
    fork(driverWatcher.watchGetQualificationIdentification),
    fork(driverWatcher.watchDeleteQualificationIdentification),
    fork(driverWatcher.watchUpdateQualificationIdentification),
    fork(bookingWatcher.watchGetBookingExpenseTypeList),
    fork(bookingWatcher.watchAddBookingExpenses),
    fork(bookingWatcher.watchUpdateBookingExpenses),
    fork(bookingWatcher.watchDeleteBookingExpenses),
    fork(bookingWatcher.watchVerifiedBookings),
    fork(bookingWatcher.watchUnverifiedBookings),
    fork(bookingWatcher.watchAddBookingInfringements),
    fork(bookingWatcher.watchUpdateBookingInfringements),
    fork(clientWatcher.watchClientInfrigement),
    fork(clientWatcher.watchClientPerformance),
    fork(clientWatcher.watchClientPerformanceclose),
    fork(clientWatcher.watchClientInfrigementSign),
    fork(driverWatcher.watchGetRemindersLists),
    fork(driverWatcher.watchDeleteReminder),
    fork(financeWatcher.watchGetPendingDocument),
    fork(financeWatcher.watchGetPayDocument),
    fork(financeWatcher.watchUnHoldDocument),
    fork(financeWatcher.watchHoldDocument),
    fork(financeWatcher.watchDeletePendingDocument),
    fork(financeWatcher.watchModifyPayDocument),
    fork(financeWatcher.watchSendToXero),
    fork(financeWatcher.watchViewPayDocument),
    fork(driverWatcher.watchGetAssignedTickets),
    fork(financeWatcher.watchGetPendingDocument),
    fork(financeWatcher.watchPayRateListing),
    fork(financeWatcher.watchGetHolidayListing),
    fork(financeWatcher.watchDeleteMultipleHolidays),
    fork(financeWatcher.watchAddAdjustment),
    fork(financeWatcher.watchUpdatePayDocument),
    fork(financeWatcher.watchUpdateAdjustment),
    fork(financeWatcher.watchDeleteAdjustment),
    fork(financeWatcher.watchCreatePayDocument),
    fork(bookingWatcher.watchUpdateBookingDetails),
    fork(bookingWatcher.watchUpdateBookingClientDetails),
    fork(bookingWatcher.watchUpdateBasicBookingDetails),

    fork(bookingWatcher.watchChangeBookingAWRScope),
    fork(bookingSetupWatcher.watchAddContractType),
    fork(bookingSetupWatcher.watchDeleteContractType),
    fork(bookingSetupWatcher.watchUpdateContractType),
    fork(bookingSetupWatcher.watchAddQualification),
    fork(bookingSetupWatcher.watchGetUserList),
    fork(bookingSetupWatcher.watchUpdateQualification),
    fork(bookingSetupWatcher.watchGetContractTypeList),
    fork(bookingSetupWatcher.watchDeleteQualification),
    fork(financeWatcher.watchDeletePayRate),
    fork(financeWatcher.watchBuildPayDocuments),
    fork(financeWatcher.watchupdateRateCard),
    fork(financeWatcher.watchDeleteRateCard),
    fork(financeWatcher.watchupdateRateRules),
    fork(financeWatcher.watchviewPayRate),
    fork(financeWatcher.watchAddPayCard),
    fork(financeWatcher.watchAddPayRules),
    fork(financeWatcher.watchGetBuildProgress),
    fork(financeWatcher.watchViewPayRateHistory),
    fork(financeWatcher.watchCopyPayRate),
    fork(bookingSetupWatcher.watchGetUserGroupList),
    fork(bookingSetupWatcher.watchDeleteUserGroup),
    fork(bookingSetupWatcher.watchAddUserGroup),
    fork(bookingSetupWatcher.watchGetBookingExpenseTypes),
    fork(bookingSetupWatcher.watchAddBookingExpenseType),
    fork(bookingSetupWatcher.watchDeleteExpenseType),
    fork(bookingSetupWatcher.watchGetTags),
    fork(bookingSetupWatcher.watchDeleteTag),
    fork(bookingSetupWatcher.watchCreateTag),
    fork(bookingSetupWatcher.watchUpdateTag),
    fork(bookingSetupWatcher.watchGetCandidateRatingTypeList),
    fork(bookingSetupWatcher.watchDeleteCandidateRatingType),
    fork(bookingSetupWatcher.watchAddCandidateRatingType),
    fork(bookingSetupWatcher.watchUpdateCandidateRatingType),
    fork(bookingSetupWatcher.watchUpdateBookingExpenseType),
    fork(bookingSetupWatcher.watchUpdateUserGroup),
    fork(bookingSetupWatcher.watchGetCandidateInfringementTypeList),
    fork(bookingSetupWatcher.watchDeleteCandidateInfringementType),
    fork(bookingSetupWatcher.watchAddNewCandidateInfringementType),
    fork(bookingSetupWatcher.watchUpdateCandidateInfringementType),
    fork(financeWatcher.watchGetUserClientList),
    fork(financeWatcher.watchGetVerifyBookingList),
    fork(supplierWatcher.watchSupplierList),
    fork(supplierWatcher.watchAddSupplier),
    fork(supplierWatcher.watchUpdateSupplier),
    fork(supplierWatcher.watchGetSupplierEmailTemplate),
    fork(supplierWatcher.watchDeleteSupplier),
    fork(supplierWatcher.watchAddStsCandidate),
    fork(supplierWatcher.watchGetListSupplierCandidatesPaged),
    fork(supplierWatcher.watchSendSupplierEmail),
    fork(supplierWatcher.watchSaveSupplierClientApproval),
    fork(bookingSetupWatcher.watchGetCandidatePerformanceTypeList),
    fork(bookingSetupWatcher.watchDeleteCandidatePerformanceType),
    fork(bookingSetupWatcher.watchAddCandidatePerformanceType),
    fork(bookingSetupWatcher.watchUpdateCandidatePerformanceType),
    fork(bookingSetupWatcher.watchGetNonConversionReasonList),
    fork(bookingSetupWatcher.watchGetInsuranceCategoryList),
    fork(bookingSetupWatcher.watchDeleteInsuranceCategoryType),
    fork(bookingSetupWatcher.watchAddInsuranceCategoryType),
    fork(bookingSetupWatcher.watchUpdateInsuranceCategoryType),
    fork(siteWatcher.watchGetSiteDocument),
    fork(siteWatcher.watchDeleteSiteDocument),
    fork(bookingSetupWatcher.watchAddNonConversionReasonType),
    fork(bookingSetupWatcher.watchUpdateNonConversionReasonType),
    fork(bookingSetupWatcher.watchDeleteNonConversionReasonType),
    fork(bookingSetupWatcher.watchGetQualificationTitleList),
    fork(bookingSetupWatcher.watchGetLeadSourceList),
    fork(bookingSetupWatcher.watchAddLeadSourceType),
    fork(bookingSetupWatcher.watchUpdateLeadSourceType),
    fork(bookingSetupWatcher.watchDeleteLeadSourceType),
    fork(bookingSetupWatcher.watchGetReasonForLeavingList),
    fork(bookingSetupWatcher.watchAddLeavingReasonType),
    fork(bookingSetupWatcher.watchUpdateLeavingReasonType),
    fork(bookingSetupWatcher.watchDeleteLeavingReasonType),
    fork(bookingSetupWatcher.watchDeleteBankHoliday),
    fork(bookingSetupWatcher.watchAddBankHoliday),
    fork(bookingSetupWatcher.watchGetBankHolidayList),
    fork(driverWatcher.watchGetPayeStatementList),
    fork(driverWatcher.watchDeleteUserTags),
    fork(driverWatcher.watchListAttachments),
    fork(driverWatcher.watchDownloadDocument),
    fork(driverWatcher.watchDeleteMultiDocument),
    fork(bookingSetupWatcher.watchGetServiceCompanyList),
    fork(bookingSetupWatcher.watchGetIdentificationNameList),
    fork(bookingSetupWatcher.watchGetBookingType),
    fork(bookingSetupWatcher.watchAddBookingType),
    fork(bookingSetupWatcher.watchUpdateBookingType),
    fork(bookingSetupWatcher.watchDeleteBookingType),
    fork(bookingSetupWatcher.watchAddNewIdentification),
    fork(bookingSetupWatcher.watchUpdateIdentification),
    fork(bookingSetupWatcher.watchDeleteIdentification),
    fork(driverWatcher.watchDeleteSmartBoxMessage),
    fork(siteWatcher.watchUpdateSiteInfringement),
    fork(bookingWatcher.watchUpdateVerifyBookingDetails),
    fork(driverWatcher.watchRefreshMetaCache),
    fork(bookingSetupWatcher.watchUpdateBankHoliday),
    fork(bookingSetupWatcher.watchGetPhoneNumbersList),
    fork(clientWatcher.watchAddClientInfo),
    fork(clientWatcher.watchUpdateClientInfo),
    fork(clientWatcher.watchDeleteClientInfo),
    fork(bookingSetupWatcher.watchGetClientDocumentTypeList),
    fork(bookingSetupWatcher.watchUpdateClientDocumentType),
    fork(bookingSetupWatcher.watchAddClientDocumentType),
    fork(bookingSetupWatcher.watchDeleteClientDocumentType),
    fork(userWatcher.watchGetObjectPermissions),
    fork(bookingSetupWatcher.watchAddServiceCompany),
    fork(driverWatcher.watchSendAppMessage),
    fork(driverWatcher.watchGetAppHistory),
    fork(driverWatcher.watchAppPersonRegister),
    fork(bookingSetupWatcher.watchUpdateServiceCompany),
    fork(bookingSetupWatcher.watchDeleteServiceCompany),
    fork(driverWatcher.watchDetailPersonSearch),
    fork(candidateModalWatcher.watchConfirmOrUnconfrimTimeOff),
    fork(bookingWatcher.watchChangeSiteDetails),
    fork(bookingWatcher.watchAddBookingImport),
    fork(bookingWatcher.watchGetWeeklySingleBooking),
    fork(reportWatcher.watchGetReportList),
    fork(reportWatcher.watchGetReportInfo),
    fork(driverWatcher.watchSendBulkSMS),
    fork(calendarWatcher.watchGetMonthlyBookingsOverview),
    fork(driverWatcher.watchSendSMSToPhoneNumber),
    fork(driverWatcher.watchSendAvailabilityRequest),
    fork(driverWatcher.watchSendCalendarUpdated),
    fork(driverWatcher.watchSendNoWorkTomorrow),
    fork(driverWatcher.watchSendCalendarSummary),
    fork(driverWatcher.watchSendBookingDetails),
    fork(driverWatcher.watchSendBookingHoursQuery),
    fork(driverWatcher.watchGetCandidateLinks),
    fork(driverWatcher.watchGetAccruedHolidayAdjustmentsList),
    fork(driverWatcher.watchGetHolidayShiftsAdjustmentsList),
    fork(driverWatcher.watchAddAccruedHolidayAdjustments),
    fork(driverWatcher.watchAddHolidayShiftsAdjustments),
    fork(driverWatcher.watchDeleteAccruedHolidayAdjustments),
    fork(driverWatcher.watchDeleteHolidayShiftsAdjustments),
    fork(bookingSetupWatcher.watchGetStartDay),
    fork(bookingSetupWatcher.watchSendPayDocuments),
    fork(bookingSetupWatcher.watchAddReasonCode),
    fork(bookingSetupWatcher.watchUpdateReasonCode),
    fork(bookingSetupWatcher.watchDeleteReasonCode),
    fork(bookingSetupWatcher.watchGetAllSiteList),
    fork(bookingSetupWatcher.watchGetAllSiteListPaged),
    fork(bookingSetupWatcher.watchGetAllMobileAppLinks),
    fork(bookingSetupWatcher.watchAddMobileAppLink),
    fork(bookingSetupWatcher.watchGetAllReasonCodes),
    fork(bookingSetupWatcher.watchUpdateMobileAppLink),
    fork(bookingSetupWatcher.watchDeleteMobileAppLink),
    fork(calendarWatcher.watchUpdateNightOut),
    fork(calendarWatcher.watchHoldFromAppSingle),
    fork(calendarWatcher.watchHoldFromAppMultiple),
    fork(driverWatcher.watchGetRtwChecks),
    fork(driverWatcher.watchGetHolidayPeriod),
    fork(driverWatcher.watchCreateDetailedBookings),
    fork(driverWatcher.watchGetCandidateDefaultExpense),
    fork(driverWatcher.watchCandidateClientDefaultExpense),
    fork(siteWatcher.watchGetClientCandidateHistory),
    fork(siteWatcher.watchClientCandidateEmail),
    fork(driverWatcher.watchSetPassword),
    fork(driverWatcher.watchGetAppDetails),
    fork(driverWatcher.watchClaimProfileID),
    fork(driverWatcher.watchClearAppProfile),
    fork(driverWatcher.watchAddPersonToLBAPI),
    fork(financeWatcher.watchMergeDocument)
  ]);
}
