import keyMirror from "keymirror";

const actions = {
  CANDIDATE_SELECTED_CLIENT: null,
  CANDIDATE_SELECTED_SITE: null,
  CANDIDATE_SELECTED_STATUS: null,
  SITE_SELECTED_STATUS: null,
  CANDIDATE_STATUS: null,
  CANDIDATE_SEARCH_KEYWORD: null,
  CANDIDATE_SELECTED: null,
  CANDIDATE_SELECTED_CLEAR: null,
  CANDIDATE_SELECTED_CLIENT_CLEAR: null,
  CANDIDATE_FILTER_CLEAR: null,
  SELECTED_DATE: null,
  // site list
  SITE_SELECTED_CLIENT: null,
  SITE_SELECTED_BRANCHES: null,
  SITE_SELECTED_TAGS: null,
  SITE_SEARCH_KEYWORD: null,
  SITE_SELECTED_STATUS: null,
  WORK_HISTORY_SELECTED_STATUS: null,
  SELECTED_SITE_CANDIDATE_TYPE: null,
  SITE_SELECTED_PERFORMANCE: null,
  SITE_SELECTED_INFRINGEMENT_TYPE: null,
  SITE_SELECTED_INFRINGEMENT_STATUS: null,
  SITE_SELECTED_PAYMENT: null,
  SITE_SELECTED_CLEAR: null,
  SITE_FILTER_CLEAR: null,
  SITE_SELECTED: null,

  USER_SELECTED_CLIENT: null,
  USER_SEARCH_KEYWORD: null,
  USER_SELECTED_STATUS: null,
  USER_SELECTED_CLEAR: null,
  USER_FILTER_CLEAR: null,
  USER_SELECTED: null,

  // candidate list
  CURRENT_USER_ROLE: null,
  CANDIDATE_SELECTED_TAGS: null,
  CANDIDATE_SELECTED_BRANCHES: null,
  ASSIGN_CANDIDATE_SELECTED_TAGS: null,
  ASSIGN_CANDIDATE_SELECTED_BRANCHES: null,
  CANDIDATE_SELECTED_SHIFTS: null,
  CANDIDATE_DAILY_BOOKING_FILTER: null,
  CANDIDATE_DAILY_BOOKING_CLEAR: null,
  CANDIDATE_VERIFY_BOOKING_FILTER: null,
  CANDIDATE_VERIFY_BOOKING_CLEAR: null,
  WEEKLY_BOOKING_FILTER: null,
  WEEKLY_BOOKING_FILTER_CLEAR: null,
  MONTHLY_BOOKING_FILTER: null,
  MONTHLY_BOOKING_FILTER_CLEAR: null,

  FINANCE_SELECTED_START_DATE: null,
  FINANCE_SELECTED_END_DATE: null,
  FINANCE_SELECTED_BRANCHES: null,
  FINANCE_SELECTED_STATUS: null,
  FINANCE_SEARCHED_KEYWORD: null
};

export default keyMirror(actions);
